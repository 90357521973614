<template>
    <div class="o-layout o-layout--large">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center">
                    <title-text :title="PROVIDER.Portfolio.PortfolioDashboard.Title" />
                    <recess-button :title="BUTTON_TEXT.howItWorks" icon="info" variant="variant4" class="d-flex qa-portfolio-dashboard-info-button" @click.native.prevent="openDashboardInfoModal()" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <recess-card variant="variant1">
                        <recess-button
                            :title="PROVIDER.Portfolio.PortfolioDashboard.PortfolioListButton"
                            variant="secondary"
                            class="qa-portfolio-overview-button"
                            :url="{ name: 'portfolio-overview' }"
                        />
                    </recess-card>
                </div>
            </div>

            <span v-if="isTestUser && isTstEnvironment" class="u-color-white">I love you all. -Sara</span>

            <div class="row my-5">
                <div class="col-12">
                    <div class="c-dashboard">
                        <div class="c-dashboard-item">
                            <h1 class="c-dashboard-title qa-total-courses-count">
                                {{totalCoursesCount}}
                            </h1>

                            <h6>
                                {{ PROVIDER.Portfolio.PortfolioDashboard.OverviewTotalCourses }}
                            </h6>
                        </div>
                        <div class="c-dashboard-item">
                            <h1 class="u-color-success c-dashboard-title qa-total-courses-assorted-product-count">
                                {{ activeCoursesCount }}
                            </h1>
                            <h6>
                                {{ PROVIDER.Portfolio.PortfolioDashboard.OverviewTotalAssortedProducts }}
                            </h6>
                        </div>
                        <div class="c-dashboard-item">
                            <h1 class="c-dashboard-title qa-rating-portfolio">
                                {{ averageCourseRating.productRatingAverage }}
                                </h1>
                            <h6>
                                {{ PROVIDER.Portfolio.PortfolioDashboard.OverviewRatingPortfolio.firstPart }}
                                {{ averageCourseRating.productRatingsCount }}
                                {{ PROVIDER.Portfolio.PortfolioDashboard.OverviewRatingPortfolio.secondPart }}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h2 class="mb-2 pl-4">
                        {{ PROVIDER.Portfolio.PortfolioDashboard.AddCourseTitle }}
                    </h2>
                </div>

                <div class="col-12">
                    <recess-card variant="variant1">
                        <recess-button
                            :title="PROVIDER.Portfolio.PortfolioDashboard.CreateCourseButton"
                            variant="secondary"
                            class="qa-dashboard-course-create-button"
                            :url="{ name: 'course-create' }"
                        />
                        <recess-button :title="PROVIDER.Portfolio.PortfolioDashboard.EdudexImportListButton" variant="secondary" class="qa-edudex-button ml-4" :url="{ name: 'edudex-import' }" />
                        <recess-button :title="PROVIDER.Portfolio.PortfolioDashboard.ViaApiButton" variant="secondary" class="qa-apim-button ml-4" target="_blank" url="https://developers.edums.nl" />
                    </recess-card>
                </div>
            </div>
        </div>

        <dashboard-info-pop-up-component v-show="isDashboardInfoModalVisible" :is-modal-visible="isDashboardInfoModalVisible" dashboard-type="portfolio" @close="closeDashboardInfoModal" />
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { mapState } from 'vuex'
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { getItemsFromSearchIndex, getItemById  } from '../../../../shared/api/SharedClient'
import UserService from '../../../../shared/services/UserService'

import TitleText from '@/components/atoms/TitleText'
const DashboardInfoPopUpComponent = () => import('@/components/molecules/DashboardInfoPopUpComponent')

export default {
    name: 'PortfolioDashboard',
    components: {
        TitleText,
        DashboardInfoPopUpComponent
    },
    data() {
        return {
            PROVIDER,
            userService: new UserService(),
            isAdministrator: false,
            isTestUser: false,
            isTstEnvironment: process.env.NODE_ENV === 'test',
            BUTTON_TEXT,
            isDashboardInfoModalVisible: false,
            totalCourses: 0,
            activeCourses: 0,
            averageCourseRating: {
                productRatingAverage: 'n.v.t',
                productRatingsCount: 0
            },
            totalCoursesFilter: {
                filter: ''
            }, // MD: All courses (not deleted + deleted)
            activeCoursesFilter: {
                filter: ''
            }, // MD: Not deleted courses
            providerId: ''
        }
    },
    computed: {
        ...mapState('providerModule', ['selectedProvider', 'provider']),
        activeCoursesCount(){
            return this.activeCourses
        },
        totalCoursesCount(){
            return this.totalCourses
        }
    },
    async created() {
        this.providerId = this.selectedProvider.id
        this.isAdministrator = await this.userService.isAdministrator()
        this.isTestUser = await this.userService.isTestUser()
        await this.performRequests()
    },
    watch: {
        'selectedProvider.id'() {
                this.providerId = this.selectedProvider.id
                this.performRequests()
        }
    },
    methods: {
        openDashboardInfoModal() {
            this.isDashboardInfoModalVisible = true
        },
        closeDashboardInfoModal() {
            this.isDashboardInfoModalVisible = false
        },
        async performRequests() {
            const promises = []

            this.setTotalCoursesFilter()
            promises.push(this.getTotalCourses())

            this.setActiveCoursesFilter()
            promises.push(this.getActiveCourses())

            promises.push(this.getAverageCourseRatings())

            await Promise.all(promises)
        },
        setTotalCoursesFilter() {
            let filter = ''
            if (this.isAdministrator && this.providerId) {
                filter = `providerId eq '${this.providerId}'`
            }
            this.totalCoursesFilter.filter = filter
        },
        setActiveCoursesFilter() {
            const currentDate = new Date().toJSON().slice(0, 10)

            let filter = `(deleted eq false and (endDate eq null or endDate gt ${currentDate}))`
            if (this.providerId) {
                filter += ` and providerId eq '${this.providerId}'`
            }
            this.activeCoursesFilter.filter = filter
        },
        async getTotalCourses() {
            try {
                const response = await getItemsFromSearchIndex(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.courses, API_CALL_URL_PATHS.search, this.totalCoursesFilter)
                if (!response) return
                this.totalCourses = response.count
            } catch (error) {
                console.error('Something went wrong while retrieving courses', error)
            }
        },
        async getActiveCourses() {
            try {
                const response = await getItemsFromSearchIndex(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.courses, API_CALL_URL_PATHS.search, this.activeCoursesFilter)
                if (!response) return
                this.activeCourses = response.count
            } catch (error) {
                console.error('Something went wrong while retrieving courses', error)
            }
        },
        async getAverageCourseRatings() {
            if (!this.providerId)
            {
                return
            }
                
            try {
                 const response = await getItemById(
                     process.env.VUE_APP_PROVIDER_API_URL,
                     API_CALL_URL_PATHS.averageRating,
                     this.providerId,
                     null,
                     false
                 )


                this.averageCourseRating = {
                    productRatingAverage: response.productRatingAverage,
                    productRatingsCount: response.productRatingsCount
                }
               } catch (error) {
                console.error(
                    'Something went wrong while retrieving average course ratings',
                    error
                )
            }
        }
    }
}
</script>
